import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import store from '@/store/index';

Vue.use(VueRouter);
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true,
});

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: {
          requiresAuth: false,
        },
      },
    ]
  },
  {
    path: '/agendamento',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'Appointment',
        meta: {
          title: 'Agendamento'
        },
        component: () => import(/* webpackChunkName: "appointment" */ '../views/schedule/make/Appointment.vue'),
      },
      {
        path: 'informacoes',
        name: 'AppointmentGeneralInfo',
        component: () => import(/* webpackChunkName: "appointment-general-info" */ '../views/schedule/make/AppointmentGeneralInfo.vue'),
      },
    ]
  },
  {
    path: '/consulta-agendamento',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'ConsultaAgendamento',
        props: (route) => ({ cancelado: Boolean(route.query.cancelado) }),
        component: () => import(/* webpackChunkName: "appointment-index" */ '../views/schedule/consult/Index.vue'),
      },
      {
        path: ':appointmentType',
        name: 'ConsultaAgendamentoDesktop',
        props: (route) => ({ appointmentType: String(route.params.appointmentType) }),
        component: () => import(/* webpackChunkName: "appointment-desktop-index" */ '../views/schedule/consult/Desktop.vue'),
      },
      {
        path: ':appointmentType/agendamento/:appointmentId',
        name: 'appointmentSingle',
        props: (route) => ({
          appointmentType: String(route.params.appointmentType),
          appointmentId: String(route.params.appointmentId)
        }),
        component: () => import(/* webpackChunkName: "appointment-detail" */ '../views/schedule/consult/Single.vue'),
      },
    ],
  },
  {
    path: '/faq',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'FAQ',
        component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue'),

      },
    ]
  },
  {
    path: '/vinculacao-online',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'VinculacaoOnline',
        component: () => import(/* webpackChunkName: "online-relation" */ '../views/onlineRelation/Index.vue'),

      },
      {
        path: '/selecionar-unidade',
        name: 'SelecionarUnidade',
        props: (route) => ({ redirectToAttachmentForm: Boolean(route.params.redirectToAttachmentForm) }),
        component: () => import(/* webpackChunkName: "attachment-files" */ '../views/onlineRelation/SelectUnity.vue'),
      },
      {
        path: '/anexar-documentos',
        name: 'AnexarDocumentos',
        props: (route) => ({ unityId: String(route.params.selectedUnityId) }),
        component: () => import(/* webpackChunkName: "relation-form" */ '../views/onlineRelation/RelationForm.vue'),
      },
      {
        path: '/vinculacao-enviada',
        name: 'VinculacaoEnviada',
        props: (route) => ({
          relationData: Object(route.params.relationData),
          username: String(route.params.username)
        }),
        component: () => import(/* webpackChunkName: "vinculacao-enviada" */ '../views/onlineRelation/FinishRelation.vue'),
      },
      {
        path: '/estado-vinculacao',
        name: 'EstadoVinculacao',
        component: () => import(/* webpackChunkName: "vinculacao-feita" */ '../views/onlineRelation/StatusRelation.vue'),
      },
    ]
  },
  {
    path: '/acessibilidade',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'Accessibility',
        component: () => import(/* webpackChunkName: "accessibility" */ '../views/Accessibility.vue'),
      },
    ]
  },
  {
    path: '/lista-de-unidades',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'UnitiesList',
        component: () => import(/* webpackChunkName: "unities-list" */ '../views/UnitiesList.vue'),

      },
    ]
  },
  {
    path: '/lista-de-atalhos',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'ShortcutList',
        component: () => import(/* webpackChunkName: "shortcut-list" */ '../views/ShortcutList.vue'),

      },
    ]
  },
  {
    path: '/avaliacao',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        meta: {
          requiresAuth: true,
        },
        name: 'Rating',
        component: () => import(/* webpackChunkName: "rating" */ '../views/rating/index.vue'),
      },
      {
        path: '',
        name: 'Success',
        component: () => import(/* webpackChunkName: "rating-success" */ '../views/rating/Success.vue'),
      },
    ]
  },
  {
    path: '/avalie',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: ':whatsappCode',
        name: 'RatingRemote',
        props: (route) => ({ whatsappCode: String(route.params.whatsappCode) }),
        component: () => import(/* webpackChunkName: "RatingRemote" */ '@/views/rating/Remote.vue'),
      },
    ]
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: 'erro',
        name: 'Error',
        component: () => import(/* webpackChunkName: "Error" */ '../views/ErrorPage.vue'),

      },
    ]
  },
  // --- Not found route
  {
    path: '*',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'ErrorNotFound',
        component: () => import(/* webpackChunkName: "Error" */ '../views/ErrorNotFound.vue'),
      },
    ]
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let redirectPath = null;
  const redirectQuery = {};

  redirectQuery.redirect = to.fullPath;
  // Checking if the route requires auth
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.userToken) {
      store.dispatch('loginKeycloak', `${window.location.origin}`);
      next({
        path: redirectQuery,
      });

      return;
    }

    if (!from.name &&
      (to.name === 'AnexarDocumentos' ||
        to.name === 'SelecionarUnidade' ||
        to.name === 'VinculacaoEnviada')
    ) {
      next({
        name: 'VinculacaoOnline',
      });

      return;
    }
  }

  else if (redirectPath) {
    next({
      path: to.fullPath,
      query: redirectQuery,
    });

    return;
  }

  if (store.getters.userToken && to.fullPath.includes('#')) {
    next({
      path: to.fullPath.split('#')[0]
    });

    return;
  }

  next();
});

export default router;
