import api from '@/api/survey';

export default {
  state: {
    survey: null,
  },
  getters: {
    survey: (state) => state.survey,
  },
  actions: {
    async getSurvey({ commit }, surveyId) {
      try {
        const res = await api.getSurvey(surveyId);

        commit('SET_SURVEY', res.data);

        return Promise.resolve(res);
      } catch (error) {
        commit('SET_SURVEY', null);


        return Promise.reject(error);
      }
    },

    async postSurvey({ commit }, payload) {
      try {

        const { surveyId, answers, auth } = payload;

        const res = await api.postSurvey(surveyId, answers, auth);

        commit('SET_SURVEY', res.data);

        return Promise.resolve(res);
      } catch (error) {
        commit('SET_SURVEY', null);

        return Promise.reject(error);
      }
    },
  },
  mutations: {
    SET_SURVEY(state, survey) {
      state.survey = survey;
    },
  }
};
