import { surveyApi } from '@/config/axios/index.js';

export default {
  getSurvey(surveyId) {
    return surveyApi({
      method: 'GET',
      url: `/api/v1/survey/${surveyId}`,
      data: null,
    });
  },

  postSurvey(surveyId, data, auth) {
    const unauthenticated = auth ? '' : '/unauthenticated';

    return surveyApi({
      method: 'POST',
      url: `/api/v1/survey/${surveyId}${unauthenticated}/answers`,
      data,
    });
  }
};
