/* eslint-disable no-param-reassign */
//Imports
import Vue from 'vue';
import '@/registerServiceWorker';
import '@/config/globalComponents/index'; // GlobalComponents
import '@/config/veeValidate/index'; // VeeValidate
import '@/config/vueTelInput/index'; // Telephone Input
import '@/config/vmodal/index'; // Modal
import '@/config/vueTheMask/index'; // Vue mask
import '@/config/sentry/index'; // Sentry
import api from '@/config/axios/index';
import i18n from '@/config/i18n/index';
import dayjs from '@/config/dayjs/index'; // DayJS
import router from './router';
import store from './store';
import App from './App.vue';
import VueGtag from 'vue-gtag';
import { setAuthHeader } from '@/config/axios/index.js';

// --- Prototype
Vue.prototype.$dayjs = dayjs;

Vue.config.productionTip = false;

let analyticsPMVV = process.env.VUE_APP_ANALYTICS_GTAG_CITY ?
  { id: process.env.VUE_APP_ANALYTICS_GTAG_CITY } :
  {};

Vue.use(VueGtag, {
  includes: [analyticsPMVV],
  config: { id: process.env.VUE_APP_ANALYTICS_GTAG }
});

new Vue({
  name: 'AppVue',
  router,
  store,
  i18n,
  created(){
    api.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response) {
          /**
           * Checking if some request response has status code 401
           * if it's occurs then logout the user and reload page
          */
          if (error.response.status === 401) {
            // Reset any query string params
            await this.$router.replace({ name: 'Home', query: {} }).catch(() => {});
            this.$store.dispatch('userLogout');
          }
        }

        return Promise.reject(error);
      },
    );

    this.$store.dispatch('resetModal');

    var token = null;
    var refreshToken = null;
    var clientId = null;

    let urlParams = (new URL(document.location)).searchParams;

    token = urlParams.get('token');
    refreshToken = urlParams.get('refresh_token');
    clientId = urlParams.get('client_id');

    if(token && refreshToken && clientId){
      // If the URL has token and user save the data on vuex
      this.$store.dispatch('setClientId', { clientId });
      this.$store.dispatch('userSetAutoLoginData', {
        origin: 'URL',
        token: token,
        refreshToken: refreshToken,
        clientId: clientId,
      });
    } else {
      if (store.getters.userIsLogged) {
        setAuthHeader(store.getters.userToken);
        this.$store.dispatch('initKeycloak', 'check-sso');
      }else{
        this.$store.dispatch('initKeycloak');
      }
    }
  },
  render: (h) => h(App)
}).$mount('#app');
